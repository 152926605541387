import austinImage from "../assets/images/austin-linkedin-photo.jpg";
import tylerImage from "../assets/images/tyler-linked-in-photo.jpg";
import jaimeImage from "../assets/images/jaime-linked-in-photo.jpg"
export const education = [
  {
    eduImage: austinImage,
    eduDate: "austin@lcot.live",
    eduTitle: "Austin Fowler",
    eduIns: "Chief Executive Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduImage: tylerImage,
    eduDate: "tyler@lcot.live",
    eduTitle: "Tyler Williams",
    eduIns: "Chief Operating Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduImage: jaimeImage,
    eduDate: "jaime@lcot.live",
    eduTitle: "Jaime Bernal",
    eduIns: "Chief Technology Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  }
];
