export const moderators = [
  {
    eduDate: "Juelzv2",
    eduTitle: "Juelz",
    eduIns: "Admin (discord)",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "snekdash",
    eduTitle: "Snek-",
    eduIns: "Social Media and Community Manager",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  }
];
